































import { Modal } from '@/app_code/Modals/Modal'
import { BusinessBranchModel, CreateBusinessModel } from 'truemarket-modules/src/models/api/businesses'
import { UserModel } from 'truemarket-modules/src/models/api/users'
import { HttpError, Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DashboardProfile extends Vue {
  private user: UserModel | null = null;

  private firstName = '';
  private lastName = '';
  private email = '';

  private businessBranches: BusinessBranchModel[] = [];

  mounted () {
    this.user = this.$store.state.identity.User

    if (this.user === null) return

    this.firstName = this.user?.FirstName
    this.lastName = this.user?.LastName
    this.email = this.user?.Email

    Services.API.Users.GetBusinessBranches().then((branches) => {
      this.businessBranches = branches
    })
  }

  saveUserInfo () {
    console.log(this.firstName)
  }
}
